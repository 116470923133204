<ng-template #content let-modal>
    <div class="modal-header">
        <h1 class="modal-title fs-5">
            Send ICF by email </h1>
    </div>
    <div class="modal-body" [formGroup]="formGroup">
        <spinner *ngIf="loading"></spinner>

        <div class="form-group mb-3">
            <label class="form-label">Participant email</label>
            <input formControlName="participantEmail" placeholder="Enter email address" type="email" class="form-control"/>
            @if (formGroup.get('participantEmail').invalid && (formGroup.get('participantEmail').dirty || formGroup.get('participantEmail').touched)) {
                <div class="text-danger">This field is required and must be a valid email.</div>
            }
        </div>

        <div class="form-group mb-3" *ngIf="isAdolescent">
            <label class="form-label">Carer email</label>
            <input formControlName="carerEmail" placeholder="Enter email address" type="email" class="form-control"/>
            @if (formGroup.get('carerEmail').invalid && (formGroup.get('carerEmail').dirty || formGroup.get('carerEmail').touched)) {
                <div class="text-danger">This field is required and must be a valid email.</div>
            }
        </div>

        <i *ngIf="isAdolescent" style="font-size:10px">Please note that the same email can be entered in both fields</i>

        <div class="d-flex justify-content-end gap-2 mt-5">
            <button type="button" class="btn btn-success" (click)="confirm()">Submit</button>
            <button type="button" class="btn btn-outline-secondary" (click)="close()">
                Close
            </button>
        </div>
    </div>
</ng-template>