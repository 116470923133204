import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ApiService} from "../../../../../api.service";
import {CommonModule} from "@angular/common";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";
import {Participant} from '@models/participant.model';
import {SpinnerComponent} from "@components/spinner/spinner.component";

@Component({
    selector: 'send-remote-icf',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        CommonModule,
        SpinnerComponent
    ],
    templateUrl: './send-remote-icf.component.html',
})
export class SendRemoteIcfComponent {
    @Output() result = new EventEmitter<{}>();

    @ViewChild('content') content: ElementRef;
    modalRef: NgbModalRef;

    loading: boolean = false
    formGroup: FormGroup

    participant: Participant;
    isAdolescent: boolean;

    constructor(
        private formBuilder: FormBuilder,
        private apiService: ApiService,
        private modalService: NgbModal,
        private toastr: ToastrService
    ) {
    }

    open(participant: Participant, isAdolescent: boolean) {
        this.participant = participant
        this.isAdolescent = isAdolescent

        if (isAdolescent) {
            this.formGroup = this.formBuilder.group({
                participantEmail: ['', [Validators.email]],
                carerEmail: ['', [Validators.email]],
            });
        } else {
            this.formGroup = this.formBuilder.group({
                participantEmail: ['', [Validators.required, Validators.email]],
            });
        }

        this.modalRef = this.modalService.open(this.content, {
            centered: true,
        });
    }

    close() {
        this.modalRef.close()
    }

    confirm() {
        if (this.formGroup.valid) {
            this.loading = true
            const formValue = this.formGroup.value
            if ((formValue.participantEmail == undefined || formValue.participantEmail == "") && (formValue.carerEmail == undefined || formValue.carerEmail == "")) {
                this.toastr.error("You must enter at least one of the emails.")
                this.loading = false
                return
            }

            this.apiService.requestSignICF(this.participant.id, formValue).subscribe(response => {
                this.loading = false
                this.close()
                this.toastr.success(response.message)
                this.result.emit({});
            })

        } else {
            this.formGroup.markAllAsTouched()
        }
    }
}
