import {Injectable} from '@angular/core';
import {CrudService} from './crud.service';
import {Study} from '../api/api.interfaces';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../environments/environment";
import {Statistics} from "@models/studies/statistics.model";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class StudiesService extends CrudService<Study> {

    public override domainEntity = 'studies';

    constructor(public override http: HttpClient) {
        super(http);
        this.setSourceService();
    }

    getStatistics(studyId: number, sites: string, members: string): Observable<Statistics> {
        let url = `${environment.apiUrl}studies/${studyId}/statistics`;
        if (sites != "") {
            url += `?sites=${sites}`
        }
        if (members != "") {
            url += `&members=${members}`
        }
        return this.http.get<Statistics>(url);
    }
}
