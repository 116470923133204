
<div class="auth-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card h-auto">
                    <div class="card-body">
                        <div class="text-center mb-5 pb-5">
                            <img width="250" src="assets/images/logo.svg" alt="logo"/>
                        </div>
                        <h3 class="text-center">Informed Consent Form for {{ icfData?.data.type }}</h3>
                        <hr>
                        <div class="pt-5 pb-5" [innerHTML]="icfData?.data.icf"></div>

                        <form *ngIf="icfData?.data.clauses" [formGroup]="formGroup">
                            @for (item of icfData?.data.clauses; track $index) {
                                <div class="row mb-3">
                                    <div class="col-sm-12 col-lg-10" [innerHTML]="item.description"></div>
                                    <div class="col-sm-12 col-lg-2 text-end">
                                        <input type="text" formControlName="clause{{item.id}}" class="form-control" placeholder="Enter initials">
                                    </div>
                                </div>
                            }
                        </form>

                        <h5 class="mt-5">Your signature here</h5>
                        <canvas class="border border-secondary border-1" #signaturePad></canvas><br>
                        <button (click)="clear()" type="button" class="btn btn-link px-0">Clear</button>
                    </div>
                    <div class="card-footer text-center">
                        <button (click)="submit()" type="button" class="btn btn-success">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!--    <h4 class="card-header d-flex justify-content-between">-->
<!--        <span>{{ user?.firstName }} {{ user?.lastName }}</span>-->
<!--    </h4>-->
<!--    <div class="card-body">-->
<!--        <ul ngbNav #nav="ngbNav" [destroyOnHide]="false" class="nav-tabs">-->
<!--            <li [ngbNavItem]="'study-tab'">-->
<!--                <button ngbNavLink>-->
<!--                    <i class="bi bi-info-square"></i> Personal Information-->
<!--                </button>-->
<!--                <ng-template ngbNavContent>-->
<!--                    <personal-info [user]="user"></personal-info>-->
<!--                </ng-template>-->
<!--            </li>-->
<!--            <li [ngbNavItem]="'info-tab'">-->
<!--                <button ngbNavLink>-->
<!--                    <i class="bi bi-key"></i> Change Password-->
<!--                </button>-->
<!--                <ng-template ngbNavContent>-->
<!--                    <change-password></change-password>-->
<!--                </ng-template>-->
<!--            </li>-->
<!--        </ul>-->
<!--        <div [ngbNavOutlet]="nav" class="mt-2"></div>-->
<!--    </div>-->
