<h5 class="mt-5">Study Dashboard</h5>
<hr class="mt-2 mb-3" />

<spinner *ngIf="loading"></spinner>

<form class="" [formGroup]="filterForm">
    <div class="row g-2">
        <div class="form-group col-12 col-md">
            <label class="form-label">Study</label>
            <ng-select [multiple]="false" placeholder="Select 1+ options" formControlName="study">
                @for (item of studies; track $index) {
                    <ng-option value="{{ item.id }}">{{item.name}}</ng-option>
                }
            </ng-select>
        </div>
        <div class="form-group col-12 col-md">
            <label class="form-label">Site</label>
            <ng-select [multiple]="true" placeholder="Select 1+ options" formControlName="site" (change)="fetchMembers()">
                @for (item of sites; track $index) {
                    <ng-option value="{{ item.id }}">{{item.name}}</ng-option>
                }
            </ng-select>
        </div>
        <div class="form-group col-12 col-md">
            <label class="form-label">Site Team Member</label>
            <ng-select [multiple]="true" placeholder="Select 1+ options" formControlName="teamMember">
                @for (item of teamMembers; track $index) {
                    <ng-option value="{{ item.id }}">{{item.firstName}} {{item.lastName}}</ng-option>
                }
            </ng-select>
        </div>
        <div class="col-auto d-flex align-items-end flex-row-reverse pb-1">
            <button type="submit" class="btn btn-success px-3" (click)="filter()">
                <i class="bi bi-search"></i>
            </button>
        </div>
        <div class="col d-flex align-items-end pb-1">
            <a type="reset" class="btn btn-link px-3" (click)="reset()">Clear filters</a>
        </div>
    </div>
</form>

<div class="row mt-4">

    <div class="col-md-6">
        <table class="table table-striped">
            <thead>
            <tr>
                <th>Participant Stages</th>
                <th>Actuals</th>
            </tr>
            </thead>
            <tbody>
                @for (week of weeks; track week) {
                    <tr>
                        <td>Week {{week.key}} completed</td>
                        <td>{{week.value}}</td>
                    </tr>
                }
            </tbody>
        </table>
    </div>

    <div class="col-md-6">
        <div class="d-flex justify-content-center">
            <apx-chart *ngIf="!loading"
                    [series]="chartOptions.series"
                    [chart]="chartOptions.chart"
                    [labels]="chartOptions.labels"
                    [responsive]="chartOptions.responsive"
                    [legend]="chartOptions.legend"
                    [colors]="chartOptions.colors"
                    [dataLabels]="chartOptions.dataLabels"
            ></apx-chart>
        </div>

        <div class="row">
            <div class="col">
                <div class="alert alert-primary mt-5 text-center" role="alert">
                    <h3>{{sessions.scheduled}}</h3>
                    sessions<br><b>scheduled</b>
                </div>
            </div>
            <div class="col">
                <div class="alert alert-warning mt-5 text-center" role="alert">
                    <h3>{{sessions.partlyCompleted}}</h3>
                    sessions<br><b>partly completed</b>
                </div>
            </div>
            <div class="col">
                <div class="alert alert-success mt-5 text-center" role="alert">
                    <h3>{{sessions.completed}}</h3>
                    sessions<br><b>completed</b>
                </div>
            </div>
        </div>

    </div>
</div>