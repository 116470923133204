import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../api.service";
import {GenericId, RemoteSignICFInput} from "../../api/api.interfaces";
import {NgIf} from "@angular/common";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import SignaturePad, {Options} from "signature_pad";


@Component({
    selector: 'icf-anonymous',
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule,
    ],
    templateUrl: './icf-anonymous.component.html',
})
export class IcfAnonymousComponent implements OnInit, AfterViewInit {

    icfData: RemoteSignICFInput
    loading = false
    formGroup: FormGroup

    private signaturePad: SignaturePad;
    private options: Options = {}
    private token: string

    @ViewChild('signaturePad') canvas: ElementRef;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private apiService: ApiService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
    ) {
    }

    ngOnInit(): void {
        this.formGroup = this.formBuilder.group({})
        this.token = this.activatedRoute.snapshot.queryParamMap.get("token")
        this.apiService.getRemoteSignICF(this.token).subscribe({
            next: (input: RemoteSignICFInput) => {
                input.data.clauses.forEach((clause) => {
                    this.formGroup.addControl('clause' + clause.id, new FormControl('', Validators.required))
                })
                this.icfData = input
            },
            error: (error) => {
                this.toastr.error(error.error.error, ' ');
                window.location.href = 'https://www.univa.health/';
            }
        })
    }

    ngAfterViewInit() {
        this.signaturePad = new SignaturePad(this.canvas.nativeElement, this.options);
    }

    clear() {
        this.signaturePad.clear()
    }

    dataURLToBlob(dataURL) {
        var parts = dataURL.split(';base64,');
        var contentType = parts[0].split(":")[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;
        var uInt8Array = new Uint8Array(rawLength);

        for (var i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], { type: contentType });
    }

    submit() {
        let payload = []
        this.icfData.data.clauses.forEach((clause) => {
            payload.push({
                id: clause.id,
                initials: this.formGroup.value['clause' + clause.id],
                timestamp: new Date().toISOString()
            })
        })
        if (this.formGroup.valid) {
            this.apiService.postRemoteSignICF(this.token, payload, this.dataURLToBlob(this.signaturePad.toDataURL())).subscribe({
                next: (input: GenericId) => {
                    this.toastr.success(input.message);
                    window.location.href = 'https://www.univa.health/';
                },
                error: (error) => {
                    this.toastr.error(error.error.error, ' ');
                }
            })

        } else {
            this.formGroup.markAllAsTouched()
        }
    }
}
