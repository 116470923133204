import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { RouterLink, RouterOutlet } from "@angular/router";
import { ApiService } from "../../api.service";
import { CommonModule } from "@angular/common";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { PermissionPipe } from "@components/directives/permission.pipe";
import { Permissions } from "@models/permissions.enum";
import { SpinnerComponent } from "@components/spinner/spinner.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: "app-study-list",
  standalone: true,
  imports: [CommonModule,
    NgxDatatableModule,
    RouterLink, PermissionPipe,
    SpinnerComponent, NgSelectModule, ReactiveFormsModule
  ],
  providers: [ApiService],
  templateUrl: "./study-list.component.html",
  styleUrl: "./study-list.component.scss",
})
export class StudyListComponent implements OnInit, AfterViewInit {
  rows = [];
  page = {
    totalElements: 0,
    pageNumber: 0,
    size: 10,
    offset: 0,
  };
  loading = false;
  @ViewChild("datatable") datatable: ElementRef;

  Permissions = Permissions;
  filterForm: FormGroup
  statuses: {key: string, label: string}[] = [{key:'planned', label:'planned'}, {key:'ongoing', label:'ongoing'}, {key:'completed', label:'completed'}]

  constructor(private api: ApiService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.filterForm = this.fb.group({
      status: [null],
      study: [null],
    });

  }

  ngAfterViewInit(): void {
    // if (this.datatable && this.datatable.nativeElement) {
    //   const rect = this.datatable.nativeElement.getBoundingClientRect();
    //   console.log('Datatable bounding rect:', rect);
    // } else {
    //   console.error('Datatable element not found or not yet initialized.');
    // }
    this.setPage({ offset: 0 });
  }

  refreshRows() {
    this.loading = true;
    let params = {
      sort: "id",
      start: this.page.pageNumber,
      size: this.page.size,
    }
    if (this.filterForm.value.status && this.filterForm.value.status.length > 0) {
      params['status'] = this.filterForm.value.status?.join(",")
    }
    if (this.filterForm.value.study) {
      params['study'] = this.filterForm.value.study
    }
    this.api
      .studyListing(params)
      .subscribe(
        (res: any) => {
          this.loading = false;
          if (!res?.data?.length) {
            this.rows = [];
            this.page.totalElements = 0;
            return;
          }
          this.page.totalElements = res.pagination.totalCount;
          this.rows = res.data ? [...res.data] : [];
        },
        (error) => {
          console.error("Error fetching data:", error);
          this.loading = false;
        }
      );
  }

  setPage(pageInfo: any) {
    this.page.pageNumber = pageInfo.offset * this.page.size
    this.refreshRows();
  }

  reset() {
    this.filterForm.reset()
    this.filter()
  }

  filter() {
    this.page.offset = 0
    this.refreshRows()
  }
}
