import {Permissions} from '@models/permissions.enum';
import {CommonModule} from '@angular/common';
import {Component, inject, Input, SimpleChanges, ViewChild} from '@angular/core';
import {Router, RouterLink} from '@angular/router';
import {DatatableComponent, NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ApiService} from '../../../api.service';
import {ToastrService} from 'ngx-toastr';
import {ParticipantStatus, participantStatusList} from "@models/participant.model";
import {ParticipantsTableComponent} from "@components/participants/participants-table/participants-table.component";

@Component({
    selector: 'app-study-overview-data',
    standalone: true,
    imports: [CommonModule, NgxDatatableModule, RouterLink, ParticipantsTableComponent],
    providers: [ApiService],
    templateUrl: './study-overview-data.component.html',
    styleUrl: './study-overview-data.component.scss'
})
export class StudyOverviewDataComponent {
    api = inject(ApiService);
    @Input() siteLists: any = {};
    @Input() studyId: any;
    @Input() informationOfObject: any;

    @ViewChild("datatable") datatable: DatatableComponent;

    Permissions = Permissions;
    participantStatusList = participantStatusList;
    participantColumns: Array<string> = ['participantName', 'participantCode', 'site', 'status', 'nextSession']

    rows = [];
    page = {
        totalElements: 0,
        pageNumber: 0,
        size: 10,
        offset: 0,
    };

    participantRows = []
    participantsPage = {
        totalElements: 0,
        pageNumber: 0,
        size: 10,
        offset: 0,
    };

    loadingIndicator = false;
    percentage = 0;
    percentageSyle = '0%';

    constructor(
        public router: Router,
        public toastr: ToastrService
    ) {
    }

    ngOnInit() {
        this.fetchParticipantList()
    }

    ngAfterViewInit(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.
        if (this.informationOfObject.data != undefined) {
            const num: any = (this.informationOfObject.data.currentNumberOfParticipants / this.informationOfObject.data.plannedNumberOfParticipants) * 100;
            this.percentageSyle = `${Number(num).toFixed(2)}%`;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.

        if (this.siteLists.pagination != undefined && this.siteLists.data != undefined) {
            this.page.totalElements = this.siteLists.pagination.totalCount;
            this.rows = [...this.siteLists.data];
        }
        this.loadingIndicator = false;

        if (this.informationOfObject.data != undefined) {
            const num: any = (this.informationOfObject.data.currentNumberOfParticipants / this.informationOfObject.data.plannedNumberOfParticipants) * 100;
            this.percentageSyle = `${Number(num).toFixed(2)}%`;
        }
    }

    setPage(pageInfo: any) {
        this.page.pageNumber = pageInfo.offset * this.page.size;
        this.fetchSiteList();
    }

    setParticipantPage(pageInfo: any) {
        this.participantsPage.pageNumber = pageInfo.offset * this.participantsPage.size;
        this.fetchParticipantList();
    }

    fetchSiteList() {
        this.api.getStudySiteByIdQueryparm(
            {
                sort: "id",
                start: this.page.pageNumber,
                size: this.page.size,
                study: this.studyId,
            }
        ).subscribe(res => {
            this.loadingIndicator = false;
            if (!res?.data?.length) {
                return;
            }
            this.siteLists = res;
            this.page.totalElements = this.siteLists.pagination.totalCount;
            this.rows = [...this.siteLists.data];
        }, (error) => {
            this.toastr.error(error.error.error, 'Error');
            this.loadingIndicator
        });
    }

    fetchParticipantList() {
        this.api.listParticipants(this.participantsPage.pageNumber, "id", "asc", {study: this.studyId}).subscribe(res => {
            this.participantRows = res.data
            this.participantsPage.totalElements = res.pagination.totalCount
        })
    }

    protected readonly Status = ParticipantStatus;
}
