import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {RouterLink} from '@angular/router';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ApiService} from '../../api.service';
import {CommonModule} from '@angular/common';
import {PermissionPipe} from '@components/directives/permission.pipe';
import {Permissions} from '@models/permissions.enum';
import {SpinnerComponent} from '@components/spinner/spinner.component';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {Study} from "../../api/api.interfaces";

@Component({
    selector: 'app-site-list',
    standalone: true,
    imports: [
        CommonModule, NgxDatatableModule,
        RouterLink, PermissionPipe, SpinnerComponent, FormsModule, NgSelectModule, ReactiveFormsModule],
    providers: [ApiService],
    templateUrl: './site-list.component.html',
    styleUrl: './site-list.component.scss',
})
export class SiteListComponent implements OnInit, AfterViewInit {

    rows = [];

    page = {
        totalElements: 0,
        pageNumber: 0,
        size: 10
    };
    offset: number = 0

    loading = false;
    Permissions = Permissions;

    @ViewChild('datatable') datatable: ElementRef;

    filterForm: FormGroup
    studies: Study[]

    constructor(
        private api: ApiService,
        private fb: FormBuilder
    ) {
    }

    ngAfterViewInit(): void {
        this.setPage({offset: 0});
    }

    ngOnInit(): void {
        this.filterForm = this.fb.group({
            study: [null],
            name: [null],
        });
        this.fetchStudies()
    }

    fetchStudies() {
        this.api.studyListing({}).subscribe(response => {
            this.studies = response.data
        })
    }

    fetchSiteList() {
        this.loading = true;
        let params = {sort: 'id', start: this.page.pageNumber, size: this.page.size}
        if (this.filterForm.value.study != null && this.filterForm.value.study.length > 0) {
            params['study'] = this.filterForm.value.study.join(',')
        }
        if (this.filterForm.value.name != null) {
            params['name'] = this.filterForm.value.name
        }
        this.api.siteListing(params).subscribe(
            (res: any) => {
                this.loading = false;
                if (!res?.data?.length) {
                    this.rows = []
                    this.page.totalElements = 0
                    return;
                }
                this.page.totalElements = res.pagination.totalCount;
                this.rows = [...res.data];
            },
            error => {
                console.error('Fetch site list error:', error);
                this.loading = false;
            }
        );
    }

    setPage(e: any) {
        this.page.pageNumber = e.offset * this.page.size;
        this.fetchSiteList();
    }

    reset() {
        this.filterForm.reset()
        this.filter()
    }

    filter() {
        this.loading = true
        this.page.pageNumber = 0
        this.fetchSiteList()
    }
}
