
<h5 class="mt-5">Sites</h5>
<hr class="mt-2 mb-3" />

<ngx-datatable #datatable class="bootstrap" [rows]="rows"
  [messages]="{emptyMessage: 'No results found', totalMessage: 'records found'}"
  [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
  [rowHeight]="'auto'" [externalPaging]="true" [count]="page.totalElements" [limit]="page.size"
  [offset]="page.pageNumber" (page)="setPage($event)">

  <ngx-datatable-column prop="name" name="Site Name" [sortable]="true" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row?.name }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column prop="participantsRecruited" name="Participants Recruited" [sortable]="true" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row?.participantsRecruited }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column prop="id" name="Action" [sortable]="true" [flexGrow]="1">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <a [routerLink]="['/edit-site', row.id]" class="btn btn-outline-success px-3">
        View details
      </a>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

<h5 class="mt-5">Participants</h5>
<hr class="mt-2 mb-3" />
<participants-table [studyId]="studyId" [columns]="participantColumns"></participants-table>

