import {Component, Input, TemplateRef} from '@angular/core';
import {NgApexchartsModule} from "ng-apexcharts";
import {ApiService} from "../../../../api.service";
import {DatePipe, NgIf} from "@angular/common";
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {SpinnerComponent} from "../../../spinner/spinner.component";
import {Router} from "@angular/router";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'withdrawn',
    standalone: true,
    imports: [
        NgApexchartsModule,
        DatePipe,
        NgIf,
        ReactiveFormsModule,
        SpinnerComponent
    ],
    templateUrl: './withdrawn.component.html',
})
export class WithdrawnComponent {
    @Input() participantId: number

    modalRef: NgbModalRef

    loading: boolean = false
    formGroup = this.formBuilder.group({
        requestedBy: ['', Validators.required],
        reason: [''],
    });

    constructor(
        private formBuilder: FormBuilder,
        private apiService: ApiService,
        private router: Router,
        private modalService: NgbModal
    ) {
    }

    open(content: TemplateRef<any>) {
        this.modalRef = this.modalService.open(content, {centered: true});
    }

    close() {
        this.modalRef.close()
    }

    confirm() {
        if (this.formGroup.valid) {
            this.loading = true
            const withdrawnData = {
                withdrawalRequestedBy: this.formGroup.get("requestedBy").value,
                withdrawalDataPrivacyOption: "Keep data",
                withdrawalReason: this.formGroup.get("reason").value,
            }
            this.apiService.withdrawParticipant(this.participantId, withdrawnData).subscribe(response => {
                this.loading = false
                this.close()
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    this.router.navigateByUrl('/participant-overview/' + this.participantId);
                });
            })
        } else {
            this.formGroup.markAllAsTouched()
        }
    }
}
