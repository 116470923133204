<spinner *ngIf="loading"></spinner>

@if (!editing) {
    Participant Code Assigned: <b>{{ participant?.participantCode }}</b>

    @if (!loading) {
        <div class="d-flex justify-content-between">
            <div>
                @if (signaturePending) {
                    <div class="alert alert-warning mt-4" role="alert">
                        ICF signature pending
                        <button class="btn btn-link" type="button" (click)="refresh(participant?.id, editing)">
                            Check ICF status
                        </button>
                    </div>
                } @else {
                    <div class="alert alert-success mt-4" role="alert">
                        ICF already signed
                    </div>
                }
            </div>
            <div *ngIf="signaturePending">
                <button (click)="sendRemoteIcfComponent.open(participant, showCarerForm)" class="btn btn-outline-primary">Send remote ICF by email</button>
                <send-remote-icf></send-remote-icf>
            </div>
        </div>
    }
}

<h5 class="mt-4 mb-0">Personal Information</h5>
<hr class="mt-2 mb-4"/>

<div class="row" [formGroup]="patientGroup">
    <div class="col-md-6">
        <div class="form-group mb-3">
            <label class="form-label">Last Name (*)</label>
            <input formControlName="lastName" type="text" class="form-control" placeholder="Enter Last Name here"/>
        </div>
    </div>
    <div class="col-md-6">
        <div class="row align-items-end mb-3">
            <div class="col-sm-8">
                <div class="form-group mb-0">
                    <label class="form-label">Date of Birth (*)</label>
                    <input formControlName="birthDate" type="date" class="form-control"/>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-6">
        <div class="form-group mb-3">
            <label class="form-label">Email (*)</label>
            <input formControlName="email" type="email" class="form-control" placeholder="Enter email here"/>
        </div>
    </div>
    <div class="col-md-6">
        <div class="form-group mb-3">
            <label class="form-label">Biological Sex (recorded birth sex) (*)</label>
            <select formControlName="biologicalSex" class="form-select">
                <option value="">Select one option</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
            </select>
        </div>
    </div>

    <div class="col-md-6">
        <div class="form-group mb-3">
            <label class="form-label">NHS Number (optional)</label>
            <input formControlName="nhsNumber" type="text" class="form-control" placeholder="Enter NHS number here"/>
        </div>
    </div>
    <div class="col-md-6">
        <div class="form-group mb-3">
            <label class="form-label">Repeat NHS Number</label>
            <input formControlName="nhsNumber2" type="text" class="form-control" placeholder="Repeat NHS Number here"/>
        </div>
    </div>
</div>

@if (showCarerForm) {
    <h5 class="mt-4 mb-0">Personal Information (Parent/Carer)</h5>
    <hr class="mt-2 mb-4"/>

    <div class="row" [formGroup]="carerGroup">
        <div class="col-md-6">
            <div class="form-group mb-3">
                <label class="form-label">First Name</label>
                <input type="text" formControlName="firstName" class="form-control" placeholder="Enter First Name here"/>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group mb-3">
                <label class="form-label">Last Name (*)</label>
                <input type="text" formControlName="lastName" class="form-control" placeholder="Enter Last Name here"/>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group mb-3">
                <label class="form-label">Email (*)</label>
                <input type="email" formControlName="email" class="form-control" placeholder="Enter Email here"/>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label class="form-label">Date of Birth</label>
                <input type="date" formControlName="birthDate" class="form-control"/>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group mb-3">
                <label class="form-label">Relationship to Participant (*)</label>
                <select class="form-select" formControlName="relationship">
                    <option value="">Select one option</option>
                    @for (item of carerRelationship; track item) {
                        <option value="{{item.id}}">{{ item.name }}</option>
                    }
                </select>
            </div>
        </div>
    </div>
}

<h5 class="mt-4 mb-0">Participant Profile</h5>
<hr class="mt-2 mb-4"/>

<div class="row" [formGroup]="profileGroup">
    <div class="col-md-3">
        <div class="form-group mb-3">
            <label class="form-label">Initial weight (Kg) (*)</label>
            <input type="number" step=".01" formControlName="initialWeight" class="form-control" placeholder="Enter weight here"/>
        </div>
    </div>
    <div class="col-md-3">
        <div class="form-group mb-3">
            <label class="form-label">Initial height (cm) (*)</label>
            <input type="number" step=".01" formControlName="initialHeight" class="form-control" placeholder="Enter height here"/>
        </div>
    </div>

    <div class="col-md-6">
        <div class="form-group mb-3">
            <label class="form-label">Type (*)</label>
            <select class="form-select" formControlName="type" (change)="typeChanged()">
                <option [value]="null">Select one option</option>
                @for (arm of studyArms; track arm) {
                    <option value="{{ arm.arm.type }}">{{ arm.arm.name }}</option>
                }
            </select>
        </div>
    </div>

    <div class="col-md-6" *ngIf="!typeHealthySelected">
        <div class="form-group mb-3">
            <label class="form-label">What is their diagnosed type of Eating Disorders? (*)</label>
            <ng-select placeholder="Select one option" formControlName="edDiagnosis">
                @for (item of edDiagnoses; track item) {
                    <ng-option value="{{ item.id }}">{{item.name}}</ng-option>
                }
            </ng-select>
        </div>
    </div>

    <div class="col-md-6" *ngIf="!typeHealthySelected">
        <div class="form-group mb-3">
            <label class="form-label">When were they first diagnosed with an eating disorder? (*)</label>
            <div class="row">
                <div class="col-6">
                    <ng-select placeholder="Year" formControlName="firstEdDiagnosisYear">
                        @for (year of getLastYears(); track year) {
                            <ng-option value="{{ year }}">{{ year }}</ng-option>
                        }
                    </ng-select>
                </div>
                <div class="col-6">
                    <ng-select placeholder="Month" formControlName="firstEdDiagnosisMonth">
                        <ng-option value="1">January</ng-option>
                        <ng-option value="2">February</ng-option>
                        <ng-option value="3">March</ng-option>
                        <ng-option value="4">April</ng-option>
                        <ng-option value="5">May</ng-option>
                        <ng-option value="6">June</ng-option>
                        <ng-option value="7">July</ng-option>
                        <ng-option value="8">August</ng-option>
                        <ng-option value="9">September</ng-option>
                        <ng-option value="10">October</ng-option>
                        <ng-option value="11">November</ng-option>
                        <ng-option value="12">December</ng-option>
                    </ng-select>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-6" *ngIf="!typeHealthySelected">
        <div class="form-group mb-3">
            <label class="form-label">What best describes their current food intake status? (*)</label>
            <ng-select [dropdownPosition]="'top'" placeholder="Select one option" formControlName="foodIntakeStatus">
                @for (item of foodIntakeStatus; track item) {
                    <ng-option value="{{ item.id }}">{{item.name}}</ng-option>
                }
            </ng-select>
        </div>
    </div>

    <div class="col-md-6">
        <div class="form-group mb-3">
            <label class="form-label">Select any of the following that apply (*)</label>
            <ng-select [multiple]="true" [dropdownPosition]="'top'" placeholder="Select one or multiple options" formControlName="comorbidities">
                @for (item of comorbidities; track item) {
                    <ng-option value="{{ item.id }}">{{item.name}}</ng-option>
                }
            </ng-select>
        </div>
    </div>

    <div class="col-md-6">
        <div class="form-group mb-3">
            <label class="form-label">Are they taking medication (excluding supplements)? (*)</label>
            <select class="form-select" formControlName="isTakingMedication">
                <option value="">Select one option</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
            </select>
        </div>
    </div>

    @if (profileGroup?.get('isTakingMedication').value == 1) {
        <div class="col-12">
            <div class="form-group mb-3">
                <label class="form-label">Please specify all medications (generic names and dose) (*)</label>
                <textarea class="form-control" formControlName="medicationNotes"></textarea>
            </div>
        </div>
    } @else {
        <div class="col-6"></div>
    }

    <div class="col-12 mt-4">
        <div class="form-check mb-3">
            <input id="is-demo" formControlName="isDemo" type="checkbox" class="form-check-input">
            <label for="is-demo" class="form-check-label">This participant will be used for DEMO purposes</label>
        </div>
    </div>
</div>

<div class="d-flex flex-row-reverse mt-4">
    @if (editing) {
        <button class="btn btn-success" (click)="processForm()" [disabled]="signaturePending">Update</button>
    } @else {
        <button class="btn btn-success ms-3" (click)="processForm()" [disabled]="signaturePending">
            Next <i class="bi bi-arrow-right ms-1"></i>
        </button>
        <withdrawn [participantId]="participant?.id"></withdrawn>
    }
</div>
