import { User } from "./user.model"

export interface Participant {
  id: number,
  participantCode: string,
  studySite: {
    id: number,
    study: {
      id: number,
      code: string,
      name: string,
    },
    site: {
      id: number,
      name: string,
    }
  },
  createdAt: string,
  completedAt: string,
  startDate: string,
  currentWeekNumber: number,
  totalTasksScheduled: number,
  totalTasksCompleted: number,
  status: {
    id: number,
    status: ParticipantStatus,
  },
  adherenceToStudy: string,
  wizardStep: number,
  patient: {
    id: number,
    user: User,
    nhsNumber: string,
    dateOfBirth: string,
    weight: number,
    height: number,
    biologicalSex: string,
    age: number,
  },
  carer: {
    id: number,
    user: User,
    relationship: {
      id: number,
      name: string,
    }
    dateOfBirth: string,
  },
  patientEdDiagnoses: PatientEdDiagnose[],
  patientComorbidities: PatientComorbidity[],
  type: string,
  isAdolescent: boolean,
  lackOfCapability: boolean,
  isTakingMedication: boolean,
  medicationNotes: string,
  foodIntakeStatus: {
    id: number,
    status: string
  },
  icfParticipantSignedAt: string,
  icfParticipantSignatureUrl: string,
  icfCarerSignedAt: string,
  icfCarerSignatureUrl: string,
  icfStudyManagerSignedAt: string,
  icfStudyManagerSignatureUrl: string,
  icfClausesData: ParticipantIcfClauseData[],
  withdrawnAt: string,
  withdrawalRequestedBy: string,
  withdrawalReason: {
    id: number,
    reason: string,
  },
  withdrawalDataPrivacyOption: string,
  isDemo: boolean,
}

export interface PatientEdDiagnoseList {
  data: PatientEdDiagnose[]
}

export interface ParticipantIcfClauseData {
  id: number,
  initials: string,
  timestamp: string,
}

export interface PatientEdDiagnoseList {
  data: PatientEdDiagnose[]
}

export interface PatientEdDiagnose {
  id: number,
  name: string,
  firstDiagnosedAt: string,
}

export interface PatientComorbidity {
  id: number,
  name: string,
}

export interface ParticipantResponse {
  data: Participant
}

export enum ParticipantStatus {
  Draft = "Draft",
  Active = "Active",
  Withdrawn = "Withdrawn",
  Completed = "Completed",
  ScreenedOut = "Screened out",
  IcfPending = "ICF pending",
  IcfSigned = "ICF signed",
}

export const participantStatusList = [
  { value: ParticipantStatus.Draft, text: "Draft", class: "badge text-bg-secondary" },
  { value: ParticipantStatus.ScreenedOut, text: "Screened out", class: "badge text-bg-secondary" },
  { value: ParticipantStatus.IcfPending, text: "ICF pending", class: "badge text-bg-warning" },
  { value: ParticipantStatus.IcfSigned, text: "ICF signed", class: "badge text-bg-info" },
  { value: ParticipantStatus.Active, text: "Active", class: "badge text-bg-success" },
  { value: ParticipantStatus.Completed, text: "Completed", class: "badge text-bg-info" },
  { value: ParticipantStatus.Withdrawn, text: "Withdrawn", class: "badge text-bg-danger" }
]
