import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgApexchartsModule } from "ng-apexcharts";
import { ApiService } from "../../../../api.service";
import { CommonModule, DatePipe, NgIf } from "@angular/common";
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { SpinnerComponent } from "../../../spinner/spinner.component";
import { AdverseEvent, AdverseEventSeverity } from "../../../../api/api.interfaces";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Participant } from '@models/participant.model';

@Component({
  selector: 'edit-adverse-event',
  standalone: true,
  imports: [
    NgApexchartsModule,
    DatePipe,
    NgIf,
    ReactiveFormsModule,
    SpinnerComponent,
    CommonModule
  ],
  templateUrl: './edit-adverse-event.component.html',
})
export class EditAdverseEventComponent implements OnInit {
  @Output() result = new EventEmitter<{}>();

  @ViewChild('content') content: ElementRef;
  modalRef: NgbModalRef;

  participant: Participant;
  adverseEvent: AdverseEvent;
  editing: boolean;
  disabled: boolean;

  loading: boolean = false
  formGroup = this.formBuilder.group({
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
    adverseEventSeverity: ['', Validators.required],
    description: [''],
    isParticipantWithdrawn: ['', Validators.required],
    withdrawalReason: ['', (control: FormControl) => {
      if ((this.formGroup?.get("isParticipantWithdrawn").value == "1") && (control.value == "" || control.value == null)) {
        return { required: true }
      }
      return null
    }],
  }, {
    validators: [
      (control) => {
        if (control.get("startDate").value == "" || control.get("endDate").value == "") {
          return null
        }
        if (control.get("startDate").value > control.get("endDate").value) {
          control.get("endDate").setErrors({ ...(control.errors || {}), message: "This value is before the start date" })
        }
        return null
      }
    ]
  });

  severities: AdverseEventSeverity[]
  showWithdrawalReasons: boolean = false

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
  }

  open(participant: Participant, editing: boolean, disabled: boolean, adverseEvent?: AdverseEvent) {
    if (this.severities == undefined) {
      this.apiService.listAdverseEventSeverities().subscribe(response => {
        this.severities = response.data
      })
    }
    this.showWithdrawalReasons = false
    this.participant = participant
    this.editing = editing
    this.disabled = disabled
    this.formGroup.reset()
    if (this.editing) {
      this.apiService.getParticipantAdverseEvent(this.participant.id, adverseEvent.id).subscribe(response => {
        this.adverseEvent = response.data
        this.formGroup.get("startDate").setValue(this.adverseEvent.startDate)
        this.formGroup.get("endDate").setValue(this.adverseEvent.endDate)
        if (typeof this.adverseEvent.adverseEventSeverity !== "number") {
          this.formGroup.get("adverseEventSeverity").setValue(this.adverseEvent.adverseEventSeverity.id)
        }
        this.formGroup.get("description").setValue(this.adverseEvent.description)
        this.formGroup.get("isParticipantWithdrawn").setValue(this.adverseEvent.isParticipantWithdrawn ? "1" : "0")
        this.formGroup.get("withdrawalReason").setValue(this.adverseEvent.withdrawalReason)
        this.participantWithdrawnChanged()
        this.loading = false
      })
    }
    if (disabled) {
      this.formGroup.disable()
    }
    this.modalRef = this.modalService.open(this.content);
  }

  close() {
    this.modalRef.close()
  }

  startDateChanged() {
    this.formGroup.get("endDate").setValue(this.formGroup.get("endDate").value)
  }

  participantWithdrawnChanged() {
    if (this.formGroup.get("isParticipantWithdrawn").value == "1") {
      this.showWithdrawalReasons = true
    } else {
      this.showWithdrawalReasons = false
      this.formGroup.get("withdrawalReason").setValue("")
    }
  }

  confirm() {
    if (this.formGroup.valid) {
      this.loading = true
      const formValue = this.formGroup.value
      const payload = {
        startDate: formValue["startDate"],
        endDate: formValue["endDate"],
        adverseEventSeverity: Number(formValue["adverseEventSeverity"]),
        description: formValue["description"],
        isParticipantWithdrawn: formValue["isParticipantWithdrawn"] == "1",
        withdrawalReason: formValue["withdrawalReason"],
      }
      if (this.editing) {
        this.apiService.putAdverseEvent(this.participant.id, this.adverseEvent.id, payload).subscribe(response => {
          this.loading = false
          this.close()
          this.toastr.success(response.message)
          this.result.emit({});
        })
      } else {
        this.apiService.postAdverseEvent(this.participant.id, payload).subscribe(response => {
          this.loading = false
          this.close()
          this.toastr.success(response.message)
          this.result.emit({});
        })
      }
    } else {
      this.formGroup.markAllAsTouched()
    }
  }
}
