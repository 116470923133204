import { Routes } from '@angular/router';
import { SigninComponent } from './auth/signin/signin.component';
import { AddNewStudyComponent } from './studies/add-new-study/add-new-study.component';
import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';
import { StudyListComponent } from './studies/study-list/study-list.component';
import { StudyOverviewComponent } from './studies/study-overview/study-overview.component';
import { AddNewSiteComponent } from './sites/add-new-site/add-new-site.component';
import { SiteOverviewComponent } from './sites/site-overview/site-overview.component';
import { SiteListComponent } from './sites/site-list/site-list.component';
import { AddNewParticipantComponent } from './participants/add-new-participant/add-new-participant.component';
import { ParticipantOverviewComponent } from './participants/participant-overview/participant-overview.component';
import { ParticipantListComponent } from './participants/participant-list/participant-list.component';
import { authGuard } from './auth.guard';
import { EditsiteComponent } from './sites/editsite/editsite.component';
import { EditstudyComponent } from './studies/editstudy/editstudy.component';
import { roleGuard } from './role-guard';
import { Permissions } from '@models/permissions.enum';

import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import {ProfileComponent} from "./profile/profile.component";
import {IcfAnonymousComponent} from "./participants/icf-anonymous/icf-anonymous.component";

export const routes: Routes = [
  {
    path: '', redirectTo: '/study-list', pathMatch: 'full',
  },
  { path: 'signin', component: SigninComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'signin-verify', component: VerifyEmailComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'sign-remote-icf', component: IcfAnonymousComponent },
  {
    path: 'add-new-study',
    component: AddNewStudyComponent,
    canActivate: [authGuard, roleGuard],
    data: { permission: Permissions.ADD_STUDY }
  },
  {
    path: 'study-overview/:id',
    component: StudyOverviewComponent,
    canActivate: [authGuard, roleGuard],
    data: { permission: Permissions.VIEW_EDIT_STUDY }
  },
  {
    path: 'study-list',
    component: StudyListComponent,
    canActivate: [authGuard, roleGuard],
    data: { permission: Permissions.LIST_STUDIES }
  },
  {
    path: 'add-new-site',
    component: AddNewSiteComponent,
    canActivate: [authGuard, roleGuard],
    data: { permission: Permissions.ADD_SITE }
  },
  {
    path: 'edit-site/:id',
    component: EditsiteComponent,
    canActivate: [authGuard, roleGuard],
    data: { permission: Permissions.EDIT_SITE }
  },
  {
    path: 'site-overview',
    component: SiteOverviewComponent,
    canActivate: [authGuard, roleGuard],
    data: { permission: Permissions.EDIT_SITE }
  },

  {
    path: 'site-list',
    component: SiteListComponent,
    canActivate: [authGuard, roleGuard],
    data: { permission: Permissions.LIST_SITES }
  },
  {
    path: 'add-new-participant',
    component: AddNewParticipantComponent,
    canActivate: [authGuard, roleGuard],
    data: { permission: Permissions.REGISTER_PARTICIPANT }
  },
  {
    path: 'add-new-participant/:id',
    component: AddNewParticipantComponent,
    canActivate: [authGuard, roleGuard],
    data: { permission: Permissions.EDIT_PARTICIPANT }
  },
  {
    path: 'participant-overview/:id',
    component: ParticipantOverviewComponent,
    canActivate: [authGuard, roleGuard],
    data: { permission: Permissions.EDIT_PARTICIPANT }
  },
  {
    path: 'participant-list',
    component: ParticipantListComponent,
    canActivate: [authGuard, roleGuard],
    data: { permission: Permissions.LIST_PARTICIPANTS }
  },
  {
    path: 'sessions',
    canActivate: [authGuard, roleGuard],
    loadChildren: () => import('./sessions/sessions.routes')
      .then(routes => routes.routes),
    data: { permission: Permissions.LIST_SESSIONS }
  },
  {
    path: 'edit-study/:id',
    component: EditstudyComponent,
    canActivate: [authGuard, roleGuard],
    data: { permission: Permissions.VIEW_EDIT_STUDY }
  },
  { path: 'not-found', component: PageNotFoundComponent },
  {
    path: '**', redirectTo: '/not-found',
  },
];
// https://ctms.nonprod.cloud.univa.health/reset-password/69078eb2e1ca52ec4367f43d60207d724075d3faf884b51e84
