import { Component } from '@angular/core';
import { Page, Session } from "../../../../api/api.interfaces";
import { ApiService } from "../../../../api.service";
import { DatePipe, NgIf } from "@angular/common";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { RouterLink } from "@angular/router";
import { Participant } from '@models/participant.model';

@Component({
  selector: 'participant-sessions',
  standalone: true,
  imports: [
    DatePipe,
    NgxDatatableModule,
    NgIf,
    RouterLink
  ],
  templateUrl: './participant-sessions.component.html',
})
export class ParticipantSessionsComponent {

  participant: Participant
  page: Page
  rows: Session[]
  sortField: string = 'scheduledAt'
  order: string = 'asc'

  constructor(
    private apiService: ApiService
  ) {
  }

  refresh(participant: Participant) {
    this.participant = participant
    this.refreshRows()
  }


  ngOnInit(): void {
    this.page = {
      size: 10,
      start: 0,
      totalCount: 0
    }
  }

  setPage(pageInfo) {
    this.page.start = pageInfo.offset * this.page.size
    this.refreshRows()
  }

  sort(pageInfo) {
    this.sortField = pageInfo.sorts[0].prop
    this.order = pageInfo.sorts[0].dir
    this.refreshRows()
  }

  refreshRows() {
    this.apiService.listParticipantSessions(this.participant.participantCode, this.page.start, this.sortField, this.order).subscribe(res => {
      this.rows = res.data
      this.page = res.pagination
    });
  }

  viewDetail(id: number) {

  }
}
